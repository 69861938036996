import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap/less/bootstrap.less'
import 'bootstrap/dist/js/bootstrap.min.js'
import './assets/css/index.less'
import $style from '@/assets/css/index.module.less'

Vue.config.productionTip = false
Vue.prototype.$style = $style;

import { scrollX, fixedHeader } from '@/directives'

Vue.directive('scrollX', scrollX)
Vue.directive('fixedHeader', fixedHeader)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
