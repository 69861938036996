import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home')
  },
  {
    path: '/list',
    name: 'list',
    component: () => import('@/views/List')
  },
  {
    path: '/question',
    name: 'question',
    component: () => import('@/views/CommonQuestion')
  },
  {
    path: '/info',
    name: 'walkerInfo',
    component: () => import('@/views/WalkerInfo')
  },
  {
    path: '/credential',
    name: 'credentialInfo',
    component: () => import('@/views/CredentialInfo')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior:() => ({y:0})
})

export default router
