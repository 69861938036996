

export const scrollX = {
    update(el){
        setTimeout(() => {
            const width = el.children[0].clientWidth;
            el.innerHTML += el.innerHTML;
            el.style.width = width * 2 + 5 + 'px'
            function move() {
                let left = parseInt(el.style.marginLeft) || 0
                if (width + left <= 0) {
                    left = 1
                }
                el.style.marginLeft = left - 1 + 'px';
            }
            el.timer = setInterval(move, 16)
        },200)
    },
    unbind(el) {
        clearInterval(el.timer);
    }
}


let scrollHeader;
function handleScroll() {
        const headerHeight = scrollHeader.clientHeight;
        let flag = true;
        const scrollTop =
            document.documentElement.scrollTop ||
            window.pageYOffset ||
            document.body.scrollTop;
        if (scrollTop >= headerHeight - 50) {
            scrollHeader.style.position = "fixed";
            scrollHeader.style.width = "100%";
            scrollHeader.style.backgroundColor = "#fff";
            if (flag) {
                scrollHeader.style.transform = `translateY(-${headerHeight}px)`;
                flag = false;
            }
            scrollHeader.style.top = headerHeight + "px";
        } else {
            scrollHeader.style.position = "fixed";
            scrollHeader.style.backgroundColor = "transparent";
            scrollHeader.style.top = 0 + "px";
            scrollHeader.style.transform = `translateY(-${0}px)`;
            flag = true;
        }
}
export const fixedHeader = {
    inserted(el) {
        scrollHeader = el;
        setTimeout(() => {
            scrollHeader.nextSibling.style.marginTop = scrollHeader.clientHeight + 'px';
        },0)
        
        
        document.addEventListener("scroll", handleScroll);
    },
    unbind() {
        document.removeEventListener("scroll", handleScroll);
    }
}